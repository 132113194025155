import * as React from 'react';
import _ from 'lodash';
import cx from 'classnames';
import {IProduct, IVideoFile} from '../../../../types/productDef';
import {ImageMode} from '@wix/wixstores-client-core/dist/es/src/media/constants';
import {Swipeable} from 'react-swipeable';
import {getMainImageRatio, isVideo} from '@wix/wixstores-client-core/dist/es/src/media/mediaService';
import s from './ResponsiveGallery.scss';
import {DotNavigation, DotNavigationTheme} from 'wix-ui-tpa';
import {ImageRatio} from '../../../../constants';
import {NoProduct} from '../../../../icons/dist';
import {IMediaItem} from '../../../../types/app-types';
import {ModalGalleryLayout} from '../../../ModalGallery/ModalGalleryLayout';
import {ProductImage} from '../../../ProductGallery/MainMedia/ProductImage/ProductImage';
import {ProvidedGlobalProps, withGlobalProps} from '../../../../providers/globalPropsProvider';

const WIXVIDEO_BASEURL = 'https://video.wixstatic.com/';

export enum ResponsiveGalleryDataHook {
  Media = 'responsive-gallery-media',
  MediaContainer = 'responsive-gallery-media-container',
  NavigationButton = 'responsive-gallery-navigation',
  NoMedia = 'responsive-gallery-no-media',
  SEO_IMAGE = 'ResponsiveGalleryDataHook.SEO_IMAGE',
}

export interface ResponsiveGalleryProps extends ProvidedGlobalProps {
  imageMode: ImageMode;
  imageRatio?: ImageRatio;
  maxRatio?: number;
  media: IProduct['media'];
  productName: string;
}

interface ResponsiveGalleryState {
  currentIndex: number;
  currentEffect: 'fadeout' | 'fadein';
  media: IProduct['media'];
  renderWidth: number;
}
@withGlobalProps
export class ResponsiveGallery extends React.Component<ResponsiveGalleryProps, ResponsiveGalleryState> {
  private readonly ref: React.RefObject<HTMLDivElement>;
  private readonly defaultRenderWidth = 100;

  public static defaultProps = {
    imageRatio: ImageRatio.AUTO,
    openZoom: _.noop,
    classNames: {},
  };

  public constructor(props: ResponsiveGalleryProps) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      currentEffect: null,
      currentIndex: 0,
      media: [],
      renderWidth: this.defaultRenderWidth,
    };
  }

  private isMediaVideo(index: number) {
    const media = this.props.media[index];
    return !!(media.mediaType === 'VIDEO' && media.videoFiles && media.videoFiles[0]);
  }

  private get ratio() {
    const {media, imageRatio, maxRatio} = this.props;
    const mainRatio = getMainImageRatio(media[0], imageRatio);
    const {height, width} = imageRatio === ImageRatio.AUTO ? media[0] : mainRatio.ratio;
    const ratio = width === 0 || height === 0 ? 3 / 2 : height / width;

    if (maxRatio && ratio > maxRatio) {
      return maxRatio;
    }
    return ratio;
  }

  private readonly navigateToMedia = (currentIndex: number) => {
    /* istanbul ignore if: todo(sagi) */
    if (this.props.media.length <= 1) {
      return;
    }

    this.fadeoutMedia();
    setTimeout(() => {
      this.setState(
        {currentIndex},
        this.isMediaVideo(currentIndex) ? () => setTimeout(() => this.fadeinMedia(), 100) : undefined
      );
    }, 100);
  };

  /* istanbul ignore next: todo(sagi) */
  private readonly goRight = () => {
    let nextIndex = this.state.currentIndex - 1;
    if (nextIndex === -1) {
      nextIndex = this.props.media.length - 1;
    }
    this.navigateToMedia(nextIndex);
  };

  /* istanbul ignore next: todo(sagi) */
  private readonly goLeft = () => {
    let nextIndex = this.state.currentIndex + 1;
    if (nextIndex > this.props.media.length - 1) {
      nextIndex = 0;
    }
    this.navigateToMedia(nextIndex);
  };

  private readonly renderImage = (mediaItem: IMediaItem, onImageLoaded?: () => any) => {
    const {imageMode, productName} = this.props;
    const {isMobile} = this.props.globals;

    return (
      <ProductImage
        mediaItem={mediaItem}
        imageMode={imageMode}
        productName={productName}
        imageLoaded={onImageLoaded}
        fluid={!isMobile}
        stretchImage={true}
      />
    );
  };

  private readonly fadeinMedia = () => {
    this.setState({currentEffect: 'fadein'});
  };

  private readonly fadeoutMedia = () => {
    this.setState({currentEffect: 'fadeout'});
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  private readonly renderMedia = () => {
    const {currentEffect, currentIndex} = this.state;
    const {media} = this.props;
    const currentMedia = media[currentIndex];
    const video = currentMedia.mediaType === 'VIDEO' && currentMedia.videoFiles && currentMedia.videoFiles[0];
    const paddingTop = Math.ceil(this.ratio * 100);

    return (
      <div data-hook={ResponsiveGalleryDataHook.MediaContainer}>
        <ModalGalleryLayout externalSelectedIndex={currentIndex}>
          {(modalZoom) => {
            return (
              <Swipeable onSwipedLeft={this.goLeft} onSwipedRight={this.goRight} trackMouse={false}>
                <div
                  key={currentIndex}
                  onClick={modalZoom.open}
                  data-hook={ResponsiveGalleryDataHook.Media}
                  style={{paddingTop: `${paddingTop}%`}}
                  className={cx(s.media, s[currentEffect], {
                    'modal-zoom-icon': modalZoom.isEnabled,
                  })}>
                  {video ? (
                    this.renderVideo(video)
                  ) : (
                    <div className={s.imageContainer}>{this.renderImage(currentMedia, this.fadeinMedia)}</div>
                  )}
                </div>
              </Swipeable>
            );
          }}
        </ModalGalleryLayout>
      </div>
    );
  };

  private renderVideo(video: IVideoFile) {
    const src = `${WIXVIDEO_BASEURL}${video.url}#t=0.001`;
    return <video controls controlsList="nodownload" src={src} />;
  }

  private readonly renderNavigation = () => {
    const {productName, media} = this.props;
    const {currentIndex} = this.state;
    if (media.length <= 1) {
      return;
    }

    const ariaLabelsArray = media.map((m) => m.altText || productName);

    return (
      <div className={s.navigation}>
        <DotNavigation
          currentIndex={currentIndex}
          data-hook={ResponsiveGalleryDataHook.NavigationButton}
          length={media.length}
          onSelect={this.navigateToMedia}
          showBorder
          theme={DotNavigationTheme.Dark}
          aria-label={ariaLabelsArray}
        />
      </div>
    );
  };

  public static getDerivedStateFromProps(
    props: ResponsiveGalleryProps,
    state: ResponsiveGalleryState
  ): {currentIndex: 0; media: IProduct['media']} {
    if (!_.isEqual(props.media, state.media)) {
      return {currentIndex: 0, media: props.media};
    }
    return null;
  }

  public componentDidMount(): void {
    this.setState({renderWidth: this.ref.current.clientWidth});
  }

  public render(): JSX.Element {
    const {media} = this.props;
    return (
      <div data-hook="responsive-gallery" ref={this.ref}>
        {media.length > 0 ? this.renderMedia() : this.renderNoMedia()}
        {this.renderNavigation()}
        {this.renderSEO()}
      </div>
    );
  }

  private renderNoMedia() {
    return (
      <div data-hook={ResponsiveGalleryDataHook.NoMedia} className={s.noMedia}>
        <NoProduct />
      </div>
    );
  }

  private getVideo(mediaItem: IMediaItem) {
    return mediaItem.videoFiles?.[0];
  }

  private renderSEO() {
    const {media} = this.props;

    return (
      <ul className={cx(s.seo_only)}>
        {media.map((m) => (
          <li key={`media-${m.url}`} data-hook={ResponsiveGalleryDataHook.SEO_IMAGE}>
            <div style={{aspectRatio: (1 / this.ratio).toString()}}>
              {isVideo(m) ? this.renderVideo(this.getVideo(m)) : this.renderImage(m)}
            </div>
          </li>
        ))}
      </ul>
    );
  }
}
